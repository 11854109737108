import {IAppScreenShotImage} from "../../../../../models/IAppScreenShotImage";

export const AppScreenShotImages: IAppScreenShotImage[] = [
  {mine: false, image: require("./app-screenshots/0 TTA 스마트시티 통합플랫폼 인증서.jpg")},
  {mine: false, image: require("./app-screenshots/0 소프트웨어 품질인증서(취득).png")},
  {mine: true, image: require("./app-screenshots/1 gis.png")},
  {mine: false, image: require("./app-screenshots/2 facility.png")},
  {mine: false, image: require("./app-screenshots/3 event.png")},
  {mine: false, image: require("./app-screenshots/4 user.png")},
  {mine: true, image: require("./app-screenshots/5 disaster.png")},
  {mine: true, image: require("./app-screenshots/6 statistics.png")},
];
