import {ReactNode} from "react";
import {ReactComponent as HOME} from "./icons/home.svg";
import {ReactComponent as WORK_LAPTOP} from "./icons/work-laptop.svg";
import {ReactComponent as FEMALE_FACE} from "./icons/user-face-female.svg";
import {ReactComponent as BROWSER} from "./icons/ai-brower-spark.svg";
import {ReactComponent as HISTORY} from "./icons/search-history.svg";

export interface ISidebarItem {
  title: string;
  to: string;
  icon: ReactNode,
}

const iconSize = 25;
export const SIDEBAR_LIST: ISidebarItem[] = [
  // {
  //   title: "홈",
  //   to: "/",
  //   icon: <HOME width={20} height={20}/>,
  // },
  {
    title: "프로필",
    to: "/",
    icon: <FEMALE_FACE width={iconSize} height={iconSize}/>,
  },
  {
    title: "경력",
    to: "/career",
    icon: <WORK_LAPTOP width={iconSize} height={iconSize}/>,
  },
  {
    title: "포트폴리오",
    to: "/portfolio",
    icon: <BROWSER width={iconSize} height={iconSize}/>,
  },
  {
    title: "업데이트 내역",
    to: "/commit",
    icon: <HISTORY width={iconSize} height={iconSize}/>,
  },
]
