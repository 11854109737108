import React from "react";
import ScreenContainer from "../../components/common/container/base-screen/BaseScreen";
import {api} from "../../api/api";
import {IGitCommit} from "../../models/IGitCommit";
import {between} from "../../utils/between";
import CommitItem from "../../components/screens/commit/CommitItem";
import Loading from "../../components/common/loading/Loading";
import "./commit.css";
import {ReactComponent as HISTORY} from "../../components/common/sidebar/icons/search-history.svg";
import dayjs from "dayjs";
import CommitHeader from "../../components/screens/commit/CommitHeader";
import Header from "../../components/common/header/Header";

interface Props {}
interface State {
  isLoading: boolean;
  data: IGitCommit[];
}

const REPOSITORY_NAME = "portfolio-2024";
export default class Commit extends React.Component<Props, State> {

  state: State = {
    isLoading: false,
    data: [],
  }

  componentDidMount() {
    this.fetchGitRepository();
  }

  fetchGitRepository = () => {
    if (this.state.isLoading) {return null;}
    this.setState({isLoading: true} as State, () => {
      api.get<IGitCommit[]>(`https://api.github.com/repos/ozee94/${REPOSITORY_NAME}/commits`).then((res) => {
        if(!between(res.status, 200, 299)) {return ;}
        this.setState({
          data: res.data,
        } as State);
      }).finally(() => {
        this.setState({
          isLoading: false
        } as State)
      });
    });
  }

  renderHeader = () => {
    return (
      <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>업데이트 내역
        <div style={{width: 20}}/>
        <HISTORY width={40} height={40}/>
      </div>
    )
  }

  render() {
    const {isLoading, data} = this.state;
    return (
      <ScreenContainer className={"commit"}>
        <Header title={this.renderHeader()} />
        {isLoading && <Loading size={50}/>}
        <div className={"commit-container"}>
          {!isLoading && data.map((commit, index) => {
            const prevCommit = data[index - 1];
            const nextCommit = data[index + 1];
            const prevCreatedAt = prevCommit ? dayjs(prevCommit.commit.author.date).format("YYYY-MM-DD") : null;
            const nextCreatedAt = nextCommit ? dayjs(nextCommit.commit.author.date).format("YYYY-MM-DD") : null;
            const createdAt = dayjs(commit.commit.author.date).format("YYYY-MM-DD");
            const isNewDay = !prevCommit || prevCreatedAt !== createdAt;
            const isEndOfDate = !nextCommit || nextCreatedAt !== createdAt;
            return (
              <>
                {isNewDay && <CommitHeader createdAt={createdAt} isFirstRow={index === 0}/>}
                <CommitItem key={commit.node_id} item={commit} isEndOfDate={isEndOfDate}/>
              </>
            )
          })}
        </div>
      </ScreenContainer>
    );
  }
}
