export const ActivityPhotos: string[] = [
  require("./activity-photos/gs/20230803_202402.jpg"),
  require("./activity-photos/gs/20230807_121825.jpg"),
  require("./activity-photos/gs/20230807_122115.jpg"),
  require("./activity-photos/gs/20230807_172017.jpg"),
  require("./activity-photos/gs/20230808_102248.jpg"),
  require("./activity-photos/gs/20230808_102250.jpg"),
  require("./activity-photos/gs/20230808_102304.jpg"),
  require("./activity-photos/gs/20230808_103803.jpg"),
  require("./activity-photos/gs/20230808_104100.jpg"),
  require("./activity-photos/gs/20230808_111847.jpg"),
  require("./activity-photos/gs/20230808_122232.jpg"),
  require("./activity-photos/gs/20230808_175904.jpg"),
  require("./activity-photos/gs/20230808_202159.jpg"),
  require("./activity-photos/gs/20230809_093840.jpg"),
  require("./activity-photos/gs/20230809_093901.jpg"),
  require("./activity-photos/gs/20230809_101733.jpg"),
  require("./activity-photos/gs/20230809_101812.jpg"),
  require("./activity-photos/gs/20230809_101845.jpg"),
  require("./activity-photos/gs/20230809_120743.jpg"),
  require("./activity-photos/gs/20230809_195049.jpg"),
  require("./activity-photos/gs/20230810_105150.jpg"),
  require("./activity-photos/gs/20230811_135824.jpg"),
  require("./activity-photos/gs/20230822_194248.jpg"),
  require("./activity-photos/gs/20230823_201348.jpg"),
  require("./activity-photos/gs/20230918_124924.jpg"),
  require("./activity-photos/gs/20231016_215122.jpg"),
  require("./activity-photos/gs/20231020_123441.jpg"),
  require("./activity-photos/gs/20231020_124520.jpg"),
];
