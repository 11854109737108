import React from "react";
import {Colors} from "../../../constants/Colors";
import dayjs from "dayjs";

interface Props {}
interface State {}

export default class Introduce extends React.Component<Props, State> {

  render() {
    const dayDiff = dayjs().diff("2021-06-14", "days");
    const workYear = Math.floor(dayDiff / 365) + 1;

    return (
      <div className={"profile-item introduce"}>
        <span className={"text"} style={{fontWeight: "bold", fontSize: 24}}>안녕하세요 🖐, 저는 <span style={{color: Colors.blue}}>Typescript & React</span> 기반의 {workYear}년차 프론트엔드 개발자입니다.</span><br/>
        <br/>
        <br/>
        <span className={"text"}>{workYear}년차로 성장하는 동안 스타트업에서 웹과 앱 서비스를 개발하고 유지보수했습니다.</span><br/>
        <span className={"text"}>입사전에는 JAVA를 중심으로 사용하였지만, 회사에 입사하면서 React, ReactNative, TypeScript 등을 익혀 여러 프로젝트를 수행하였습니다.</span><br/>
        <span className={"text"}>또한 기존 모바일 서비스의 유지보수를 위해 PHP, APACHE 및 DNS 관리 등 서버 운영을 하였습니다.</span><br/>
        <span className={"text"}>다른 언어로 시작했지만, 새로운 도전에 두려움을 느끼지 않고 받아들이며 성장할 수 있었습니다.</span><br/>
        <br/>
        <span className={"text"}>저는 새로운 것을 받아들임을 두려워하지 않고 어려움에 부딪힐 때에도 끈기 있게 문제를 해결해 나가는 개발자입니다.</span><br/>
        <br/>
        <br/>
        <span className={"text"} style={{fontSize: 16, color: "#065fd4"}}>#저는 기능을 독립적인 모듈로 구현하는 것을 좋아합니다.</span><br/>
        <span className={"text"} style={{fontSize: 16, color: "#065fd4"}}>#저는 다양한 기술 스택을 다루고 경험하는 것을 좋아합니다.</span><br/>
      </div>
    );
  }
}
