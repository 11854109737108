import {IAppScreenShotImage} from "../../../../../models/IAppScreenShotImage";

export const AppScreenShotImages: IAppScreenShotImage[] = [
  {mine: false, image: "https://ozee94.github.io/static/subway-navigation/0%20subway-navigation.mp4"},
  {mine: false, image: "https://ozee94.github.io/static/subway-navigation/0%202D%20navigation.mp4"},
  {mine: true, image: require("./app-screenshots/1 앱시작.jpg")},
  {mine: false, image: require("./app-screenshots/2 권한체크.jpg")},
  {mine: true, image: require("./app-screenshots/3 사용자유형 선택.jpg")},
  {mine: false, image: require("./app-screenshots/4 튜토리얼 - 메인페이지.jpg")},
  {mine: false, image: require("./app-screenshots/5 튜토리얼 - 길안내.jpg")},
  {mine: false, image: require("./app-screenshots/6 튜토리얼 - 경로추천.jpg")},
  {mine: false, image: require("./app-screenshots/7 튜토리얼 - 주변교통.jpg")},
  {mine: false, image: require("./app-screenshots/8 길찾기(3D).jpg")},
  {mine: false, image: require("./app-screenshots/9 길찾기(3D) - 역내시설.jpg")},
  {mine: false, image: require("./app-screenshots/10 길찾기(3D) - 경로 미리보기.jpg")},
  {mine: false, image: require("./app-screenshots/11 길찾기(3D) - 경로안내.jpg")},
  {mine: false, image: require("./app-screenshots/12 길찾기(3D) 역간이동.jpg")},
  {mine: true, image: require("./app-screenshots/13 길찾기(3D) - 경로도착.jpg")},
  {mine: true, image: require("./app-screenshots/14 길찾기(2D).jpg")},
  {mine: true, image: require("./app-screenshots/15 길찾기(2D) - 경로 미리보기.jpg")},
  {mine: true, image: require("./app-screenshots/16 길찾기(2D) - 경로안내.jpg")},
  {mine: true, image: require("./app-screenshots/17 길찾기(2D) - 경로도착.jpg")},
  {mine: true, image: require("./app-screenshots/18 목적지 검색.jpg")},
  {mine: false, image: require("./app-screenshots/19 QR 스캔.jpg")},
  {mine: true, image: require("./app-screenshots/19 주변교통.jpg")},
  {mine: true, image: require("./app-screenshots/20 주변교통 상세목록.jpg")},
  {mine: true, image: require("./app-screenshots/21 더보기 화면.jpg")},
  {mine: true, image: require("./app-screenshots/22 사용자 유형 변경.jpg")},
  {mine: true, image: require("./app-screenshots/23 대피경로 역사 목록.jpg")},
  {mine: true, image: require("./app-screenshots/24 대피경로.jpg")},
  {mine: true, image: require("./app-screenshots/25 테마여행.jpg")},
  {mine: true, image: require("./app-screenshots/26 테마여행 상세.jpg")},
  {mine: false, image: require("./app-screenshots/27 맞춤설정.jpg")},
  {mine: false, image: require("./app-screenshots/28 맞춤설정 - 언어설정.jpg")},
  {mine: true, image: require("./app-screenshots/29 고객센터.jpg")},
  {mine: true, image: require("./app-screenshots/30 고객센터 - 일대일문의.jpg")},
  {mine: true, image: require("./app-screenshots/31 고객센터 - 문의내역.jpg")},
  {mine: true, image: require("./app-screenshots/32 이용약관.jpg")},
  {mine: false, image: require("./app-screenshots/33 이용약관 - 서비스 이용약관.jpg")},
  {mine: false, image: require("./app-screenshots/34 이용약관 - 위치기반 서비스.jpg")},
  {mine: false, image: require("./app-screenshots/35 회원탈퇴.jpg")},
];
