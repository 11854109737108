import React from "react";
import Lottie from "react-lottie";
import animationData from "./not-found.json";
import {Link} from "react-router-dom";
import {Colors} from "../../constants/Colors";

interface Props {}
interface State {}

export default class NotFoundScreen extends React.Component<Props, State> {

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  render() {
    return (
      <div className={"o-full o-center"} style={{flexDirection: "column"}}>
        <Lottie
          options={this.defaultOptions}
          height={300}
          width={400}
        />
        <div style={{height: 120}}/>
        <Link to={"/"}>
          <div className={"hover-005"} style={{fontWeight: "bold", fontSize: 16, color: Colors.blue}}>
            메인으로 이동
          </div>
        </Link>
      </div>
    );
  }
}
