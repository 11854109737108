export const Colors = {
  white: "#FFFFFF",
  black: "#333333",

  blue: "#09A9FF",
  purple: "#9E5FFF",
  pink: "#FF5583",
  gray: "#9D9D9D",

  cherryRed: "#F87B67",
}
