import React from "react";
import ProjectModalContainer from "./ProjectModalContainer";
import {IProjectModalContext, ProjectModalContext} from "../../../context/ProjectModalContext";
import {PROJECT} from "../../../constants/career/ZEROWEB";
import ProjectAnuroTTAnGS from "./zeroweb/anuro-tta-gs/ProjectAnuroTTAnGS";
import ProjectBell from "./zeroweb/bell/ProjectBell";
import ProjectBiodome from "./zeroweb/biodome/ProjectBiodome";
import ProjectSubwayNavigation from "./zeroweb/subway-navigation/ProjectSubwayNavigation";
import ProjectHelperIt from "./zeroweb/helperit/ProjectHelperIt";
import ProjectWebmaster from "./zeroweb/webmaster/ProjectWebmaster";

interface Props {
  handleClose?: () => void;
}
interface State {}

export default class ProjectModal extends React.Component<Props, State> {

  static contextType = ProjectModalContext;
  context!: IProjectModalContext;

  render() {
    const {handleClose} = this.props;

    return (
      <ProjectModalContainer open={true} handleClose={handleClose}>
        <>
          {/* 2024 */}
          {this.context.name === PROJECT.NAME.BELL && <ProjectBell />}
          {this.context.name === PROJECT.NAME.SUbWAY_NAVIGATION && <ProjectSubwayNavigation />}

          {/* 2023 */}
          {this.context.name === PROJECT.NAME.ANURO_TTA_GS && <ProjectAnuroTTAnGS />}

          {/* 2022 */}
          {this.context.name === PROJECT.NAME.BIODOME && <ProjectBiodome />}

          {/* 2021 */}
          {this.context.name === PROJECT.NAME.HELPER_IT && <ProjectHelperIt />}
          {this.context.name === PROJECT.NAME.WEBMASTER && <ProjectWebmaster />}
        </>
      </ProjectModalContainer>
    );
  }
}
