import React from "react";
import {ReactComponent as AMAZON_AWS} from "./img/amazonaws.svg";
import {ReactComponent as APACHE} from "./img/apache.svg";
import {ReactComponent as TOMCAT} from "./img/apachetomcat.svg";
import {ReactComponent as BITBUCKET} from "./img/bitbucket.svg";
import {ReactComponent as GITHUB} from "./img/github.svg";
import {ReactComponent as JAVASCRIPT} from "./img/javascript.svg";
import {ReactComponent as JIRA} from "./img/jirasoftware.svg";
import {ReactComponent as LINUX} from "./img/linux.svg";
import {ReactComponent as MYSQL} from "./img/mysql.svg";
import {ReactComponent as NAVER} from "./img/naver.svg";
import {ReactComponent as NGINX} from "./img/nginx.svg";
import {ReactComponent as NODE} from "./img/nodedotjs.svg";
import {ReactComponent as ORACLE} from "./img/oracle.svg";
import {ReactComponent as REACT} from "./img/react.svg";
import {ReactComponent as SEQUELIZE} from "./img/sequelize.svg";
import {ReactComponent as SPRING} from "./img/spring.svg";
import {ReactComponent as TYPESCRIPT} from "./img/typescript.svg";

interface LanguageIconProps {
  type: "amazonAWS" | "apache" | "tomcat" | "bitbucket" | "github" | "javascript" | "jira" | "linux" |
  "mysql" | "naver" | "nginx" | "node" | "oracle" | "react" | "sequelize" | "spring" | "typescript";
  size?: number;
}
interface State {}

export default class LanguageIcon extends React.Component<LanguageIconProps, State> {
  render() {
    const {type, size = 30} = this.props;
    if (type === "amazonAWS") {return <AMAZON_AWS width={size} height={size}/>}
    if (type === "apache") {return <APACHE width={size} height={size}/>}
    if (type === "tomcat") {return <TOMCAT width={size} height={size}/>}
    if (type === "bitbucket") {return <BITBUCKET width={size} height={size}/>}
    if (type === "github") {return <GITHUB width={size} height={size}/>}
    if (type === "javascript") {return <JAVASCRIPT width={size} height={size}/>}
    if (type === "jira") {return <JIRA width={size} height={size}/>}
    if (type === "linux") {return <LINUX width={size} height={size}/>}
    if (type === "mysql") {return <MYSQL width={size} height={size}/>}
    if (type === "naver") {return <NAVER width={size} height={size}/>}
    if (type === "nginx") {return <NGINX width={size} height={size}/>}
    if (type === "node") {return <NODE width={size} height={size}/>}
    if (type === "oracle") {return <ORACLE width={size} height={size}/>}
    if (type === "react") {return <REACT width={size} height={size}/>}
    if (type === "sequelize") {return <SEQUELIZE width={size} height={size}/>}
    if (type === "spring") {return <SPRING width={size} height={size}/>}
    if (type === "typescript") {return <TYPESCRIPT width={size} height={size}/>}
  }
}
