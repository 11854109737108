import React from "react";
import LanguageBadge from "../../common/LanguageBadge";

interface Props {}
interface State {}

export default class MySkill extends React.Component<Props, State> {
  render() {
    return (
      <div className={"profile-item my-skill"}>
        <div style={{padding: "20px 0"}}>
          <span className={"profile-item-title text"}>기술스택</span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>언어</span>
          <span className={"text"}>
            <LanguageBadge logo={"JavaScript"} backgroundColor={"F7DF1E"} />
            <LanguageBadge logo={"TypeScript"} backgroundColor={"3178C6"} />
            <LanguageBadge logo={"JAVA"} backgroundColor={"3A75B0"} />
            <LanguageBadge logo={"HTML5"} name="HTML" backgroundColor={"E34F26"} />
            <LanguageBadge logo={"CSS3"} name="CSS" backgroundColor={"1572B6"} />
          </span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>프레임워크</span>
          <span className={"text"}>
            <LanguageBadge logo={"React"} name={"React"} backgroundColor={"61DAFB"} />
            <LanguageBadge logo={"React"} name={"React Native"} backgroundColor={"61DAFB"} />
            <LanguageBadge logo={"Node.js"} backgroundColor={"339933"} />
            <LanguageBadge logo={"Spring"} backgroundColor={"6DB33F"} />
            <LanguageBadge logo={"JSP"} backgroundColor={"3A75B0"} />
          </span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>서버</span>
          <span className={"text"}>
            <LanguageBadge logo={"Apache"} backgroundColor={"D22128"} />
            <LanguageBadge logo={"Nginx"} backgroundColor={"009639"} />
            <LanguageBadge logo={"ApacheTomcat"} logoColor={"333333"} backgroundColor={"F8DC75"} />
          </span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>환경</span>
          <span className={"text"}>
            <LanguageBadge logo={"Linux"} backgroundColor={"FCC624"} />
            <LanguageBadge logo={"amazonaws"} name="AWS Cloud(EC2, RDS, S3, Amplify, Route53)" backgroundColor={"232F3E"} />
            <LanguageBadge logo={"Naver"} name={"Naver Cloud(Global DNS)"} backgroundColor={"03C75A"} />
          </span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>데이터베이스</span>
          <span className={"text"}>
            <LanguageBadge logo={"MySQL"} backgroundColor={"4479A1"} />
            <LanguageBadge logo={"Oracle"} backgroundColor={"F80000"} />
            </span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>ORM</span>
          <span className={"text"}>
            <LanguageBadge logo={"MyBatis"} backgroundColor={"333333"} />
            <LanguageBadge logo={"Sequelize"} backgroundColor={"52B0E7"} />
          </span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>기타</span>
          <span className={"text"}>
            <LanguageBadge logo={"Github"} backgroundColor={"181717"} />
            <LanguageBadge logo={"Jira"} backgroundColor={"0052CC"} />
            <LanguageBadge logo={"Bitbucket"} backgroundColor={"0052CC"} />
            <LanguageBadge name={"RESTFul API"} backgroundColor={"333333"} />
          </span>
        </div>
      </div>
    );
  }
}
