import React, {CSSProperties} from "react";
import ScreenContainer from "../../components/common/container/base-screen/BaseScreen";
import Header from "../../components/common/header/Header";
import {ReactComponent as WORK_LAPTOP} from "../../components/common/sidebar/icons/work-laptop.svg";

import "./career.css";
import {Colors} from "../../constants/Colors";
import PlumpLineIcon from "../../components/common/icon/PlumpLineIcon";
import CalendarView from "./view-calendar/CalendarView";
import ListView from "./view-list/ListView";

interface Props {}
interface State {
  view: "list" | "calendar";
}

export default class Career extends React.Component<Props, State> {

  state: State = {
    view: "calendar",
  };

  renderHeader = () => {
    const {view} = this.state;
    const rowStyles = {
      display: "flex", flexDirection: "row", alignItems: "baseline"
    } as CSSProperties;

    const onChangeViewType = (view: "list" | "calendar") => this.setState({view: view});

    return (
      <div style={{...rowStyles, justifyContent: "space-between", alignItems: "flex-start"}}>
        <div style={{...rowStyles}}>경력
          <div style={{width: 20}}/>
          <WORK_LAPTOP width={40} height={40}/>
        </div>
        {
          process.env.NODE_ENV === "development" &&
          <div style={{...rowStyles}}>
            <PlumpLineIcon
              name={"calendar"} size={24} title={"달력뷰로 보기"}
              style={{stroke: view === "calendar" ? Colors.black : "#CCCCCC"}}
              onClick={() => onChangeViewType("calendar")}
            />
            <div style={{width: 10}}/>
            <PlumpLineIcon
              name={"bulletList"} size={24} title={"리스트뷰 보기"}
              style={{stroke: view === "list" ? Colors.black : "#CCCCCC"}}
              onClick={() => onChangeViewType("list")}
            />
          </div>
        }
      </div>
    )
  }


  render() {
    const {view} = this.state;
    return (
      <ScreenContainer>
        <Header title={this.renderHeader()} />
        {view === "calendar" && <CalendarView />}
        {view === "list" && <ListView />}
      </ScreenContainer>
    );
  }
}
