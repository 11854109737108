import React, {CSSProperties} from "react";
import Sidebar from "../../sidebar";
import "./baseScreen.css";

interface Props {
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  autoOpen?: boolean;
}
interface State {}

export default class BaseScreen extends React.Component<Props, State> {
  render() {
    const {children, style, className, autoOpen} = this.props;
    return (
      <div className={`o-full screen-container ${className}`} style={{...style}}>
        <Sidebar autoOpen={autoOpen === undefined ? true : autoOpen}/>
        <div style={{flex: 1, height: "100%", width: "calc(100% - 83px)", display: "flex", flexDirection: "column", position: "relative"}}>
          {children}
        </div>
      </div>
    );
  }
}
