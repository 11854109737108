import React from "react";
import {ReactComponent as LOGO} from "../../../assets/logo/me.svg";
import "./logo.css";

interface Props {
  size?: number;
}
interface State {}

export default class Logo extends React.Component<Props, State> {
  render() {
    const {size = 30} = this.props;
    return <LOGO className={"logo"} width={size} height={size}/>;
  }
}
