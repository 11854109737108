import React from "react";
import ScreenContainer from "../../components/common/container/base-screen/BaseScreen";
import {api} from "../../api/api";
import {IGitRepository} from "../../models/IGitRepository";
import {between} from "../../utils/between";
import GitRepositoryCard from "../../components/screens/portfolio/GitRepositoryCard";
import "./portfolio.css";
import Header from "../../components/common/header/Header";
import {ReactComponent as BROWSER} from "../../components/common/sidebar/icons/ai-brower-spark.svg";
import Loading from "../../components/common/loading/Loading";

interface Props {}
interface State {
  isLoading: boolean;
  data: IGitRepository[];
}

export default class Portfolio extends React.Component<Props, State> {

  state: State = {
    isLoading: false,
    data: [],
  }

  componentDidMount() {
    this.fetchGitRepository();
  }

  fetchGitRepository = () => {
    if (this.state.isLoading) {return null;}
    this.setState({isLoading: true} as State, () => {
      api.get<IGitRepository[]>("https://api.github.com/users/ozee94/repos").then((res) => {
        if(!between(res.status, 200, 299)) {return ;}
        this.setState({
          data: res.data,
        } as State);
      }).finally(() => {
        this.setState({
          isLoading: false
        } as State)
      });
    });
  }

  renderHeader = () => {
    return (
      <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>포트폴리오
        <div style={{width: 20}}/>
        <BROWSER width={40} height={40}/>
      </div>
    )
  }

  render() {
    const {isLoading, data} = this.state;
    return (
      <ScreenContainer className={"portfolio"}>
        <Header title={this.renderHeader()} />
        {isLoading && <Loading size={50}/>}
        <div className={"portfolio-container"}>
          {!isLoading && data.map(i => <GitRepositoryCard item={i}/>)}
        </div>
      </ScreenContainer>
    );
  }
}
