import {IAppScreenShotImage} from "../../../../../models/IAppScreenShotImage";

export const AppScreenShotImages: {[key in string]: IAppScreenShotImage[]} = {
  "dashboard":   [
    {mine: true, image: require("./app-screenshots/dashboard/1 메인화면.png")},
    {mine: true, image: require("./app-screenshots/dashboard/2 사용자 모달.png")},
    {mine: true, image: require("./app-screenshots/dashboard/3 사용자 역할 추가.png")},
    {mine: true, image: require("./app-screenshots/dashboard/4 관제 화면.png")},
    {mine: true, image: require("./app-screenshots/dashboard/5 설정.png")},
  ],
  "senior": [
    {mine: false, image: "https://ozee94.github.io/static/bell/0_senior.mp4"},
    {mine: true, image: require("./app-screenshots/senior/1 메인화면.png")},
    {mine: true, image: require("./app-screenshots/senior/2 휴대폰 로그인.png")},
    {mine: true, image: require("./app-screenshots/senior/3 휴대폰 로그인 - 인증번호.png")},
    {mine: true, image: require("./app-screenshots/senior/4 휴대폰 로그인 - 멀티 계정.png")},
    {mine: false, image: require("./app-screenshots/senior/5 회원가입 - 이용약관.png")},
    {mine: false, image: require("./app-screenshots/senior/6 회원가입 - 주민등록번호.png")},
    {mine: false, image: require("./app-screenshots/senior/7 회원가입 - 이름.png")},
    {mine: false, image: require("./app-screenshots/senior/8 회원가입 - 완료.png")},
    {mine: true, image: require("./app-screenshots/senior/9 메인화면.png")},
    {mine: true, image: require("./app-screenshots/senior/10 베리 획득 - 시작 전.png")},
    {mine: true, image: require("./app-screenshots/senior/11 베리 획득 - 획득 중.png")},
    {mine: true, image: require("./app-screenshots/senior/12 베리 획득 - 완료.png")},
    {mine: true, image: require("./app-screenshots/senior/13 베리 목록.png")},
    {mine: true, image: require("./app-screenshots/senior/14 내 지갑.png")},
    {mine: true, image: require("./app-screenshots/senior/15 계좌 관리 - 등록 계좌가 없을 때.png")},
    {mine: true, image: require("./app-screenshots/senior/16 계좌 관리.png")},
    {mine: true, image: require("./app-screenshots/senior/17 계좌 수정.png")},
    {mine: true, image: require("./app-screenshots/senior/18 계정 관리.png")},
    {mine: true, image: require("./app-screenshots/senior/19 고객 센터.png")},
    {mine: false, image: require("./app-screenshots/senior/20 회원 탈퇴.png")},
  ],
  "guardian": [
    {mine: false, image: "https://ozee94.github.io/static/bell/0_guardian.mp4"},
    {mine: true, image: require("./app-screenshots/guardian/1 메인화면.png")},
    {mine: true, image: require("./app-screenshots/guardian/2 시니어 설정.png")},
    {mine: true, image: require("./app-screenshots/guardian/3 메인화면 2.png")},
    {mine: true, image: require("./app-screenshots/guardian/4 시니어 실시간 위치 정보 - 위치없음.png")},
    {mine: true, image: require("./app-screenshots/guardian/5 시니어 실시간 위치 정보.png")},
    {mine: false, image: require("./app-screenshots/guardian/6 시니어 활동 차트.png")},
    {mine: true, image: require("./app-screenshots/guardian/7 시니어 이벤트 발생 내역.png")},
    {mine: true, image: require("./app-screenshots/guardian/8 내 계정 관리.png")},
    {mine: true, image: require("./app-screenshots/guardian/9 공지사항.png")},
    {mine: true, image: require("./app-screenshots/guardian/10 이벤트 푸쉬알림.png")},
    {mine: true, image: require("./app-screenshots/guardian/11 약관 및 정책.png")},
  ],
};
