import {IAppScreenShotImage} from "../../../../../models/IAppScreenShotImage";

export const AppScreenShotImages: IAppScreenShotImage[] = [
  {mine: false, image: require("./app-screenshots/1 메인화면.png")},
  {mine: false, image: require("./app-screenshots/2 회사소개.png")},
  {mine: false, image: require("./app-screenshots/3 동영상.png")},
  {mine: true, image: require("./app-screenshots/4 인증 및 실험.png")},
  {mine: true, image: require("./app-screenshots/5 관리자 - 로그인.png")},
  {mine: true, image: require("./app-screenshots/6 관리자 - 동영상 관리.png")},
  {mine: true, image: require("./app-screenshots/7 관리자 - 인증 및 실험.png")},

];
