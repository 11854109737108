import React from "react";
import {BrowserRouter, Route, RouterProps, Routes} from 'react-router-dom';
import Home from "../screens/home/Home";
import Profile from "../screens/profile/Profile";
import Career from "../screens/career/Career";
import Portfolio from "../screens/portfolio/Portfolio";
import Commit from "../screens/commit/Commit";
import NotFoundScreen from "../screens/not-found/NotFoundScreen";
import Company from "../screens/career/Company";
import ProjectModal from "../components/screens/career/ProjectModal";
import {withRouter} from "../utils/withRouter";
import {moveTo, goBack} from "../utils/NavigateUtils";
import {PROJECT} from "../constants/career/ZEROWEB";
import {ProjectModalContext} from "../context/ProjectModalContext";

interface Props {
  router: RouterProps;
}
interface State {}

class Router extends React.Component<Props, State> {

  handleClose = () => {
    if (document.referrer) {
      goBack();
    } else {
      const url = new URL(window.location.href);
      const split = url.pathname.split("/");
      moveTo(`/${split[1]}/${split[2]}`);
    }
  }

  getProjectName = () => {
    const url = new URL(window.location.href);
    const split = url.pathname.split("/");
    const projectName = split[split.length - 1];
    return projectName as PROJECT.NAME;
  }

  render() {
    const url = new URL(window.location.href);
    const isProjectUrl = /\/([^\/]+)\/([^\/]+)\/project\/([^\/]+)/.test(url.pathname);

    return (
      <Routes>
        <Route index element={<Profile />} />
        <Route path={"/"} element={<Profile />} />

        <Route path={"career"} >
          <Route index element={<Career />} />
          <Route path={":company"} element={<Company /> }>
            <Route path={"project"}>
              {isProjectUrl &&
                <Route index path={":project"} element={
                  <ProjectModalContext.Provider value={{name: this.getProjectName()}}>
                    <ProjectModal handleClose={this.handleClose} />
                  </ProjectModalContext.Provider>
                }/>
              }
            </Route>
          </Route>
        </Route>

        <Route path={"portfolio"} element={<Portfolio />}/>
        <Route path={"commit"} element={<Commit />}/>

        <Route path={'*'} element={<NotFoundScreen />} />
      </Routes>
    );
  }
}

export default withRouter(Router);
