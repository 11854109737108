import React from "react";
import BaseScreen from "../../components/common/container/base-screen/BaseScreen";
import Header from "../../components/common/header/Header";
import {ReactComponent as WORK_LAPTOP} from "../../components/common/sidebar/icons/work-laptop.svg";
import {moveTo} from "../../utils/NavigateUtils";
import {ICareer} from "../../constants/career/CAREER";
import dayjs from "dayjs";
import {ZEROWEB_CAREER} from "../../constants/career/ZEROWEB";
import "./company.css";
import {between} from "../../utils/between";
import {Link, Outlet} from "react-router-dom";
import FlowText from "../../components/common/FlowText";
import {Colors} from "../../constants/Colors";
import {Tooltip} from "@mui/material";

interface Props {}
interface State {
  selectedProject: ICareer | null;
}

export default class Company extends React.Component<Props, State> {

  state: State = {
    selectedProject: null,
  }

  renderHeader = () => {
    return (
      <>
        <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", fontSize: 30}}>
          <div className={"pointer"} style={{display: "flex", flexDirection: "row"}} onClick={() => moveTo("/career")}>
            경력
            <div style={{width: 20}}/>
            <WORK_LAPTOP width={30} height={30}/>
          </div>
          <span style={{margin: "0 20px"}}>{">"}</span>
          <div style={{fontSize: 48}}>제로웹</div>
        </div>
        <div style={{height: 20}}/>
        <div style={{fontSize: 18, fontWeight: "normal"}}>직급: 대리</div>
      </>
    )
  }

  renderContent = () => {
    let manageData: ({ row: number, s_col: number, e_col: number } & ICareer)[] = [];

    const findIndex = (item, index, s_col, e_col) => {
      const _find = manageData.filter(i => {
        return i.row === index && (between(s_col, i.s_col, i.e_col) || between(e_col + 1, i.s_col, i.e_col));
      });

      if (_find.length === 0) {
        return index;
      } else {
        return findIndex(item, index + 1, s_col, e_col);
      }
    }

    const renderEmptyCell = (backgroundColor?: string) => {
      return (
        <div key={"empty2" + Math.random()} className={"content"} style={{
          width: (50),
          backgroundColor: backgroundColor || "transparent",
          color: "transparent",
          flexDirection: "column"
        }}>
          <div>-</div>
          <div style={{height: 2}}/>
          <div style={{fontSize: 14, color: "transparent"}}>~</div>
        </div>
      )
    }

    return Object.keys(ZEROWEB_CAREER).map((year, index1) => {
      return (
        <div key={year} id={year} className={"year-container"} style={{marginLeft: index1 === 0 ? 90 : 0, marginRight: Object.keys(ZEROWEB_CAREER).length - 1 === index1 ? 90 : 0}}>
          <div className={"txt-year"}>{year}</div>
          <div style={{display: "flex", flexDirection: "row"}}>
            {
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index2) => {
                const loopDate = dayjs(`${year}-${month}`);
                if (loopDate.isAfter(dayjs())) {return null;}
                return (
                  <div style={{width: 50}}>
                    <div className={"txt-month"}>{month}월</div>
                    {
                      ZEROWEB_CAREER[year].filter(f => !dayjs(f.startDate).isAfter(loopDate, 'month')).map((item: ICareer, index3) => {
                        const startDate = dayjs(item.startDate);
                        const endDate = (item?.endDate?.length === 0) ? dayjs().endOf("month") : dayjs(item.endDate).endOf("month");
                        const hasContents = item.img || item.url;

                        const _index = index1 * 12 + index2;

                        const notShowing = manageData.findIndex(f => f.title === item.title) < 0;
                        const diff = endDate.diff(startDate, 'month');
                        if (notShowing) {
                          const s_col = _index;
                          const e_col = _index + diff;
                          const row = findIndex(item, 0, s_col, e_col);
                          manageData.push({
                            ...item, row: row,
                            s_col: s_col,
                            e_col: e_col
                          });
                        }

                        if(!notShowing) {return null;}
                        const find = manageData.find(f => f.title === item.title);
                        const findIndex1 = manageData.findIndex(f => f.title !== item.title && dayjs(f.startDate).isSame(item.startDate, "month"));
                        return (
                          <>
                            {find && Array(findIndex1 > -1 ? 0 : find.row).fill(1).map(() => renderEmptyCell())}
                            <Link to={item?.url || ""} state={{url: item?.url}}>
                              <Tooltip title={<span style={{whiteSpace: "pre-line"}}>{item.title + "\n" + item.startDate + " ~ " + item?.endDate}</span>}>
                                <div key={item.title + "-" + index3}
                                     className={`content cursor-default ${hasContents && "hover-001 pointerQ"}`}
                                     style={{
                                       width: 50 * (diff + 1),
                                       backgroundColor: hasContents ? item.color : Colors.white,
                                       border: `1px solid ${item.color}`,
                                       color: hasContents ? Colors.white : Colors.black,
                                       zIndex: 1,
                                     }}
                                >
                                  <div style={{
                                    display: "inline-flex",
                                    position: "sticky",
                                    left: 0,
                                    overflow: "hidden",
                                    flexDirection: "column"
                                  }}>
                                    <div style={{fontWeight: hasContents ? "bold" : "normal"}}>
                                      <FlowText>{item.title}</FlowText>
                                    </div>
                                    <div style={{height: 2}}/>
                                    <div style={{fontSize: 14, color: hasContents ? "#FFFFFFA0" : "#999999A0"}}>
                                      <FlowText>{item.startDate}~{item?.endDate}</FlowText>
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            </Link>
                          </>
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      )
    });
  }

  render() {
    return (
      <BaseScreen autoOpen={false}>
        <Header title={this.renderHeader()}/>
        <div style={{
          margin: "0px 90px 15px",
          textAlign: "right",
          padding: "0 15px",
          fontWeight: "bold",
          color: Colors.gray
        }}>
          배경이 있는 프로젝트명을 누르면<br/>
          상세한 정보를 확인할 수 있습니다
        </div>
        <div style={{height: 50}}/>

        <div className={"company-container"}>
          {this.renderContent()}
        </div>

        <Outlet/>
      </BaseScreen>
    );
  }
}
