import React from 'react';
import "./paperFlake.css";
import LanguageIcon from "../icon/language-icon/LanguageIcon";
import {Tooltip} from "@mui/material";
import PlumpLineIcon from "../icon/PlumpLineIcon";

interface Props {}
interface State {
  isPaused: boolean;
}

export default class PaperFlake extends React.Component<Props, State> {

  state: State = {
    isPaused: false,
  };

  getDefaultValue = () => {
    return {
      width: parseInt(String(Math.random() * (35 - 10 + 1) + 20)),
      left: parseInt(String(Math.random() * (100 - 0 + 1) + 0)),
      top: parseInt(String(Math.random() * (100 - 5 + 1) + 5)),
      duration: parseInt(String(Math.random() * (30 - 5 + 1) + 10)),
      skew: Math.random() < 0.5 ? parseInt(String(Math.random() * (40 - 0 + 1) + 0)) : parseInt(String(Math.random() * (40 - 0 + 1) + 0)) * -1,
    }
  };

  icons = [
    {...this.getDefaultValue(), name: "amazonAWS"},
    {...this.getDefaultValue(), name: "apache"},
    {...this.getDefaultValue(), name: "tomcat"},
    {...this.getDefaultValue(), name: "bitbucket"},
    {...this.getDefaultValue(), name: "github"},
    {...this.getDefaultValue(), name: "javascript"},
    {...this.getDefaultValue(), name: "jira"},
    {...this.getDefaultValue(), name: "linux"},
    {...this.getDefaultValue(), name: "mysql"},
    {...this.getDefaultValue(), name: "naver"},
    {...this.getDefaultValue(), name: "nginx"},
    {...this.getDefaultValue(), name: "node"},
    {...this.getDefaultValue(), name: "oracle"},
    {...this.getDefaultValue(), name: "react"},
    {...this.getDefaultValue(), name: "sequelize"},
    {...this.getDefaultValue(), name: "spring"},
    {...this.getDefaultValue(), name: "typescript"}
  ];

  renderPowder = () => {
    const {isPaused} = this.state;
    return this.icons.map(i => {
      return (
        <Tooltip title={i.name}>
          <div
            style={{
              width: i.width, // 20-35
              left: `${i.left}%`, // 0 - 100
              top: `-${i.top}%`,
              animationDuration: `${i.duration}s`, // 3- 20
              transform: `skew(${i.skew}deg)`, // -40 - 40
            }}
            className={`paper-flake ${isPaused && "paper-flake-animation-stop"}`}
            key={i.name}>
            <LanguageIcon type={i.name as any} size={i.width}/>
          </div>
        </Tooltip>
      )
    })
  }

  render() {
    const {isPaused} = this.state;

    return (
      <>
        <div className={"pointer"} style={{position: "absolute", right: 30, top: 30, zIndex: 2}} onClick={() => this.setState({isPaused: !this.state.isPaused})}>
          {isPaused && <PlumpLineIcon name={"play"} onClick={() => this.setState({isPaused: false})}/>}
          {!isPaused && <PlumpLineIcon name={"pause"} onClick={() => this.setState({isPaused: true})}/>}
        </div>
        <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, overflow: 'hidden', zIndex: isPaused ? 1 : -1}}>
          {this.renderPowder()}
        </div>
      </>
    );
  }
}
