import React from "react";
import {Colors} from "../../../../../constants/Colors";
import {AppScreenShotImages} from "./AppScreenShotImages";
import Image from "../../../../common/image/Image";

interface Props {}
interface State {}

export default class BiodomeDescription extends React.Component<Props, State> {

  render() {
    return (
      <div className={"project-modal-content-description"}>
        <div className={"item"}>
          <div className={"title"}>프로젝트 설명</div>
          <div className={"description"}>
            BIODOME 홈페이지 제작
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>기간</div>
          <div className={"description"}>2022.02 ~ 2022.03<br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>역할/기여도</div>
          <div className={"description"}>
            웹 관리자 <span style={{fontWeight: "bold"}}>100%</span><br/>
            웹 프론트 <span style={{fontWeight: "bold"}}>20%</span><br/>
            API <span style={{fontWeight: "bold"}}>100%</span><br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>주요업무</div>
          <div className={"description"}>
            1. 웹 관리자 구현 및 RESTful API 데이터 연동<br/>
            2. 메인 홈페이지 RESTful API 데이터 연동 <br/>
            3. 데이터베이스 구축 및 API 개발<br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>사이트</div>
          <div className={"description"}>
            <a target={"_blank"} href={"https://www.biodome.co.kr"} style={{color: Colors.blue}}>https://www.biodome.co.kr</a>
          </div>
        </div>

        <div className={"title"} style={{paddingTop: 12}}>사진</div>
        <div className={"image-list"}>
          {AppScreenShotImages.map((i) => {
            return (
              <Image key={i.image} src={i.image} className={`${i.mine && "mine"}`} showFullScreen/>
            )
          })}
        </div>
      </div>
    );
  }
}
