import React from "react";
import {Colors} from "../../../../../constants/Colors";
import {AppScreenShotImages} from "./AppScreenShotImages";
import Image from "../../../../common/image/Image";

interface Props {}
interface State {
  selectedTab: "dashboard" | "senior" | "guardian";
}

export default class BellDescription extends React.Component<Props, State> {

  state: State = {
    selectedTab: "dashboard",
  }

  onClickTab = (selectedTab: "dashboard" | "senior" | "guardian") => {
    this.setState({selectedTab: selectedTab} as State);
  }

  render() {
    const {selectedTab} = this.state;
    return (
      <div className={"project-modal-content-description"}>
        <div className={"item"}>
          <div className={"title"}>프로젝트 설명</div>
          <div className={"description"}>
            <span style={{fontWeight: "bold", color: Colors.pink}}>시니어 케어 서비스</span><br />
            <span style={{fontWeight: "bold"}}>대시보드</span> - 벨과 관련된 모든 조직, 시니어, 센서등 모든 서비스들을 관리<br />
            <span style={{fontWeight: "bold"}}>시니어 앱</span> - 시니어들이 사용하는 앱으로 운동을 유도하는 헬스케어 리워드 서비스<br />
            <span style={{fontWeight: "bold"}}>보호자 앱</span> - 연결되어 있는 시니어의 활동정보를 모니터하는 서비스
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>기간</div>
          <div className={"description"}>2023.06 ~<br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>역할/기여도</div>
          <div className={"description"}>
            웹 대시보드 <span style={{fontWeight: "bold"}}>50%</span><br/>
            시니어 앱 프론트 <span style={{fontWeight: "bold"}}>95%</span><br/>
            보호자 앱 프론트 <span style={{fontWeight: "bold"}}>90%</span>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>주요업무</div>
          <div className={"description"}>
            1. [WEB] 대시보드 웹 개발 – 사용자 관리, 기업 관리 화면 구현<br/>
            2. [WEB] 사용자의 실시간 위치를 구글지도에 표출
            3. [WEB] 대시보드 RESTful API 데이터 연동<br/>
            4. [WEB] WebSocket 통신으로 실시간 데이터 변경 적용<br/>
            5. [APP] 시니어 앱, 보호자 앱 개발 및 RESTful API 데이터 연동<br/>
            6. [APP] GoogleMaps를 이용하여 시니어 위치 실시간으로 표출<br/>
            7. [APP] PlayStore에 Bell앱 등록
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>앱</div>
          <div className={"description"}>
            <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=kr.bell"} style={{color: Colors.blue, marginRight: 20}}>Play Store</a>
          </div>
        </div>


        <div style={{display: "flex", flexDirection: "row", paddingTop: 12, alignItems: "center"}}>
          <div className={"title"}>사진</div>

          <div style={{display: "flex", flexDirection: "row"}}>
            <span className={`image-tab pointer ${selectedTab === "dashboard" && "selected"}`} onClick={() => this.onClickTab("dashboard")}>대시보드</span>
            <span className={`image-tab pointer ${selectedTab === "senior" && "selected"}`} onClick={() => this.onClickTab("senior")}>시니어</span>
            <span className={`image-tab pointer ${selectedTab === "guardian" && "selected"}`} onClick={() => this.onClickTab("guardian")}>보호자</span>
          </div>
        </div>
        <div className={"image-list"}>
          {AppScreenShotImages[selectedTab].map((i) => {
            return (
              <Image key={i.image} src={i.image} className={`${i.mine && "mine"}`} showFullScreen/>
            )
          })}
        </div>
      </div>
    );
  }
}
