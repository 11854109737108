import React from "react";
import ProjectModalContainer from "../../ProjectModalContainer";
import LanguageBadge from "../../../../common/LanguageBadge";
import PlumpLineIcon from "../../../../common/icon/PlumpLineIcon";
import ProjectModalDescriptionTab from "../../ProjectModalDescriptionTab";
import ProjectModalPhotoTab from "../../ProjectModalPhotoTab";
import {Colors} from "../../../../../constants/Colors";
import {IProjectModalContext, ProjectModalContext} from "../../../../../context/ProjectModalContext";
import {PROJECT} from "../../../../../constants/career/ZEROWEB";

interface Props {}
interface State {
  activeTab: "description" | "photo" | "blog";
}

export default class ProjectBell extends React.Component<Props, State> {

  static contextType = ProjectModalContext;
  context!: IProjectModalContext;

  state: State = {
    activeTab: "description",
  }

  onChangeTab = (tab: "description" | "photo" | "blog") => this.setState({activeTab: tab} as State);

  render() {
    const {activeTab} = this.state;
    return (
      <div>
        <ProjectModalContainer.Header>
          <ProjectModalContainer.Title>{PROJECT.toString(this.context.name)}</ProjectModalContainer.Title>

          <ProjectModalContainer.Information>
            {/* 기술 스택 */}
            <ProjectModalContainer.Badge>
              <LanguageBadge logo={"React"} name={"React"} backgroundColor={"61DAFB"} />
              <LanguageBadge logo={"React"} name={"React Native"} backgroundColor={"61DAFB"} />
              <LanguageBadge logo={"TypeScript"} backgroundColor={"3178C6"} />
              <LanguageBadge name={"RESTFul API"} backgroundColor={"333333"} />
              <LanguageBadge logo={"Google"} name={"Google Maps"} backgroundColor={"EA4335"} />
              <LanguageBadge logo={"MUI"} backgroundColor={"007FFF"} />
              <LanguageBadge logo={"MobX"} backgroundColor={"FF9955"} />
            </ProjectModalContainer.Badge>

            <ProjectModalContainer.Tab>
              <PlumpLineIcon name={"gridRules"} title={"소개"}
                             style={{stroke: activeTab === "description" ? Colors.black : Colors.gray}}
                             onClick={() => this.onChangeTab("description")}/>
              <PlumpLineIcon name={"photo"} title={"사진첩"}
                             style={{stroke: activeTab === "photo" ? Colors.black : Colors.gray}}
                             onClick={() => this.onChangeTab("photo")}/>
              {/*<PlumpLineIcon name={"notebook"} title={"일기"}*/}
              {/*               style={{stroke: activeTab === "blog" ? Colors.black : Colors.gray}}*/}
              {/*               onClick={() => this.onChangeTab("blog")}/>*/}
            </ProjectModalContainer.Tab>
          </ProjectModalContainer.Information>
        </ProjectModalContainer.Header>

        <ProjectModalContainer.Content>
          {activeTab === "description" && <ProjectModalDescriptionTab />}
          {activeTab === "photo" && <ProjectModalPhotoTab />}
        </ProjectModalContainer.Content>
      </div>
    );
  }
}
