import React, {ReactNode} from "react";

interface Props {
  title?: ReactNode;
}
interface State {}

export default class Header extends React.Component<Props, State> {
  render() {
    const {title} = this.props;
    return (
      <div style={{padding: "90px", fontWeight: "bold", fontSize: 48}}>
        {title}
      </div>
    );
  }
}
