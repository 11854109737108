import React, {CSSProperties, MouseEventHandler} from "react";
import {Tooltip, TooltipProps} from "@mui/material";
import {ReactComponent as description} from "../../../assets/icons/plump-line/description.svg";
import {ReactComponent as gridRules} from "../../../assets/icons/plump-line/grid-rules.svg";
import {ReactComponent as landscape} from "../../../assets/icons/plump-line/landscape.svg";
import {ReactComponent as photo} from "../../../assets/icons/plump-line/photo.svg";
import {ReactComponent as notebook} from "../../../assets/icons/plump-line/notebook.svg";
import {ReactComponent as play} from "../../../assets/icons/plump-line/play.svg";
import {ReactComponent as pause} from "../../../assets/icons/plump-line/pause.svg";
import {ReactComponent as bulletList} from "../../../assets/icons/plump-line/bullet-list.svg";
import {ReactComponent as calendar} from "../../../assets/icons/plump-line/calendar.svg";

const IconName = {
  description: description,
  gridRules: gridRules,
  landscape: landscape,
  photo: photo,
  notebook: notebook,
  play: play,
  pause: pause,
  bulletList: bulletList,
  calendar: calendar,
}

export type IconNameKey = keyof typeof IconName;

const icon: { [key in IconNameKey]: any } = {} as any;

Object.keys(IconName).forEach((key) => {
  icon[key as IconNameKey] = IconName[key as IconNameKey];
});

interface Props {
  className?: string;
  name: IconNameKey;
  onClick?: MouseEventHandler | undefined;
  title?: string;
  followCursor?: boolean;
  style?: CSSProperties;
  size?: number;
  pointer?: boolean;
  placement?: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

interface State {}

export default class PlumpLineIcon extends React.Component<Props, State> {
  render() {
    const { name, onClick, title, followCursor = false, style, className, placement, size = 30, pointer } = this.props;
    const Icon = icon[name] as any;

    return (
      <Tooltip title={title || ""} followCursor={followCursor} placement={placement}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Icon
            className={`${onClick && "hover-02 pointer"} ${className}`}
            style={{width: size, height: size, cursor: (onClick || pointer) ? "pointer" : "", ...style}}
            onClick={onClick}
          />
        </div>
      </Tooltip>
    );
  }
}
