import React from "react";
import CareerInformation from "../../../components/screens/career/CareerInformation";
import BaseModal from "../../../components/common/container/base-modal/BaseModal";
import {CAREER, CAREERS, ICareer} from "../../../constants/career/CAREER";
import {moveTo} from "../../../utils/NavigateUtils";
import dayjs from "dayjs";
import {Colors} from "../../../constants/Colors";
import getColor = CAREER.getColor;

interface Props {}
interface State {
	open: boolean;
	selectedCareer: ICareer | null;
}

export default class CalendarView extends React.PureComponent<Props, State> {

	state: State = {
		open: false,
		selectedCareer: null,
	};

	handleClose = () => this.setState({open: false} as State);

	renderContent = () => {
		let manageData: ({ row: number, col: number } & ICareer)[] = [];

		const onClick = (career: ICareer) => {
			if (career.img) {
				this.setState({
					open: true,
					selectedCareer: career,
				})
			} else if (career.url) {
				moveTo(career.url);
			}
		}

		return Object.keys(CAREERS).map((year, index1) => {
			return (
				<div key={year} id={year} className={"year-career-container"}>
					<div className={"txt-year"}>{year}</div>
					{
						CAREERS[year].map((item: ICareer, index2) => {
							const startYear = dayjs(item.startDate).year();
							const endYear = (item?.endDate?.length === 0) ? dayjs().year() : dayjs(item.endDate).year();
							const isPeriod = typeof item?.endDate === "string";
							const diff = isPeriod ? (year === endYear.toString() ? 1 : endYear - startYear + 1) : 1;
							const hasContents = item.img || item.url;
							if (diff > 1) {
								manageData.push({...item, row: index2, col: index1 + diff - 1});
							}
							return (
								<>
									{
										manageData.length > 0 && manageData.filter(f => dayjs(f.endDate).year() >= startYear).map((item3, index3) => {
											if (dayjs(item3.startDate).year() === startYear) {return null;}
											if(item3.row === index2 && item3.col > index3) {
												return (
													<div key={"empty" + index2} className={"content"} style={{
														width: (350 * diff),
														backgroundColor: "transparent", color: "transparent",
														flexDirection: "column"
													}}>
														<div>-</div>
														<div style={{height: 2}}/>
														<div style={{fontSize: 14, color: "transparent"}}>~</div>
													</div>
												)
											}
										})
									}
									<div key={index2} className={`content ${hasContents && "hover-001 pointerQ"}`} onClick={() => hasContents && onClick(item)} style={{
										width: (350 * diff) + (10 * (diff - 1)),
										backgroundColor: isPeriod ? getColor(item?.type) : "white",
										color: hasContents ? (isPeriod ? Colors.white : Colors.black) : (isPeriod ? "#FFFFFFA0" : Colors.gray),
									}}>
										<div key={Math.random()} style={{display: "inline-flex", position: isPeriod ? "sticky" : "inherit", left: 0, flexDirection: "column"}}>
											<div style={{display: "flex", flexDirection: "row", alignItems: "center", fontWeight: hasContents ? "bold" : "normal"}}>
												{(!isPeriod && getColor(item?.type)) && <div style={{width: 10, height: 10, borderRadius: 100, marginRight: 10, backgroundColor: getColor(item?.type)}}/>}
												{item.title}
											</div>
											<div style={{height: 2}}/>
											<div style={{
												fontSize: 14,
												color: isPeriod ? "#FFFFFFA0" : "#999999A0"
											}}>{item.startDate} {isPeriod && "~"} {item?.endDate}</div>
										</div>
									</div>
								</>
							);
						})
					}
				</div>
			)
		});
	}

	render() {
		const {selectedCareer, open} = this.state;
		return (
			<div>
				<CareerInformation />
				<div style={{height: 50}}/>
				<div className={"career-container"}>
					{this.renderContent()}
				</div>

				{
					selectedCareer &&
          <BaseModal open={open} handleClose={this.handleClose} closeBackDropClick>
            <img style={{borderRadius: 20, maxHeight: "80%"}} src={require(`../../../assets/images/certificate/${selectedCareer.img}`)} alt={""}/>
          </BaseModal>
				}
			</div>
		);
	}
}
