import React from "react";

interface Props {
  badgeStyle?: "plastic" | "flat" | "for-the-badge" | "social";
  logo?: string;
  name?: string;
  logoColor?: string;
  backgroundColor: string;
}
interface State {}

/*
*  icon 참고 사이트
*  https://simpleicons.org/?q=react-native
* */
export default class LanguageBadge extends React.Component<Props, State> {
  render() {
    const {
      badgeStyle = "flat-square",
      logo,
      name,
      logoColor = "white",
      backgroundColor,
    } = this.props;
    return (
      <img alt={logo || name} src={`https://img.shields.io/badge/${name || logo}-${backgroundColor}?style=${badgeStyle}${logo ? "&logo=" + logo : ""}&logoColor=${logoColor}`}/>
    );
  }
}
