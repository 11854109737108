import React from "react";
import {IProjectModalContext, ProjectModalContext} from "../../../context/ProjectModalContext";
import {PROJECT} from "../../../constants/career/ZEROWEB";
import {ActivityPhotos as AnuroTTAActivityPhotos} from "./zeroweb/anuro-tta-gs/ActivityPhotosTTA";
import {ActivityPhotos as AnuroGSActivityPhotos} from "./zeroweb/anuro-tta-gs/ActivityPhotosGS";
import {ActivityPhotos as SubwayNavigationActivityPhotos} from "./zeroweb/subway-navigation/ActivityPhotos";
import Image from "../../common/image/Image";

interface Props {
  photoName?: string;
}
interface State {}

export default class ProjectModalPhotoTab extends React.Component<Props, State> {

  static contextType = ProjectModalContext;
  context!: IProjectModalContext;

  getPhotoData = () => {
    const {photoName} = this.props;
    switch (this.context.name) {
      case PROJECT.NAME.ANURO_TTA_GS:
        if (photoName === "photo-tta") {
          return AnuroTTAActivityPhotos;
        } else {
          return AnuroGSActivityPhotos;
        }
      case PROJECT.NAME.BELL:
        return ;
      case PROJECT.NAME.BIODOME:
        return ;
      case PROJECT.NAME.SUbWAY_NAVIGATION:
        return SubwayNavigationActivityPhotos;
      default :
        return [];
    }
  }

  render() {
    return (
      <div className={"project-modal-content-photo"}>
        {(this.getPhotoData() || []).map((i) => {
          return (
            <Image key={i} src={i} showFullScreen/>
          )}
        )}
      </div>
    );
  }
}
