import React from "react";
import {Colors} from "../../../../../constants/Colors";
import {AppScreenShotImages} from "./AppScreenShotImages";
import Image from "../../../../common/image/Image";

interface Props {}
interface State {}

export default class SubwayNavigationDescription extends React.Component<Props, State> {

  render() {
    return (
      <div className={"project-modal-content-description"}>
        <div className={"item"}>
          <div className={"title"}>프로젝트 설명</div>
          <div className={"description"}>
            <span style={{fontWeight: "bold", color: Colors.pink}}>부산 지하철 역사 실내·외 연계 길 안내 서비스</span><br />
            실내에서는 역사와 동일한 3D 맵 기반으로 역사내에 설치되어 있는 비콘으로 현재 위치를 잡아 길 안내를 제공하며<br />
            실외는 SK open API를 이용하여 보행자 경로 안내를 제공<br />
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>기간</div>
          <div className={"description"}>2021.12 ~ 2024.03<br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>역할/기여도</div>
          <div className={"description"}>앱 프론트 50%</div>
        </div>

        <div className={"item"}>
          <div className={"title"}>주요업무</div>
          <div className={"description"}>
            1. 주변교통, 더보기 화면 구현<br />
            2. TMap 보행자 경로 API를 활용하여 2D 경로 안내 구현<br />
            3. RESTful API 데이터 연동<br />
            4. 외부 협력사로부터 제공받은 역사 모델링 파일을 앱 내의 UnityView 좌표 체계에 맞게 규칙을 찾아 조정하여 적용<br />
            5. 제공받은 3D 모델링을 UnityView라이브러리를 통해 앱으로 표출
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>앱</div>
          <div className={"description"}>
            <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=cloud.zeroweb.navigation.subway"} style={{color: Colors.blue, marginRight: 20}}>Play Store</a>
            <a target={"_blank"} href={"https://apps.apple.com/us/app/%EB%8B%A4%EA%B0%80%EC%B9%98%EB%82%98%EB%9E%80%ED%9E%88-%EB%B0%B0%EB%A6%AC%EC%96%B4%ED%94%84%EB%A6%AC-%EC%A7%80%ED%95%98%EC%B2%A0-%EB%82%B4%EB%B9%84%EA%B2%8C%EC%9D%B4%EC%85%98/id1597697656"} style={{color: Colors.blue}}>App Store</a>
          </div>
        </div>

        <div className={"title"} style={{paddingTop: 12}}>사진</div>
        <div className={"image-list"}>
          {AppScreenShotImages.map((i) => {
            return (
              <Image key={i.image} src={i.image} className={`${i.mine && "mine"}`} showFullScreen/>
            )
          })}
        </div>
      </div>
    );
  }
}
