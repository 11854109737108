import React from "react";
import "./gitRepositoryCard.css";
import {IGitRepository} from "../../../models/IGitRepository";
import {DateUtils} from "../../../utils/DateUtils";
import dayjs from "dayjs";
import {Colors} from "../../../constants/Colors";
import {api} from "../../../api/api";
import {between} from "../../../utils/between";
import Loading from "../../common/loading/Loading";

interface Props {
  item: IGitRepository;
}
interface State {
  isLoading: boolean;
  commits: number | null;
}

export default class GitRepositoryCard extends React.Component<Props, State> {

  state: State = {
    isLoading: false,
    commits: null,
  }

  componentDidMount() {
    const {item} = this.props;
    const {isLoading} = this.state;
    if(isLoading) {return null;}

    this.setState({
      isLoading: true
    } as State, () => {
      api.get(`https://api.github.com/repos/ozee94/${item.name}/commits`).then(r => {
        if (!between(r.status, 200, 299)) {return ;}
        this.setState({commits: r.data.length} as State);
      }).finally(() => {
        this.setState({isLoading: false} as State);
      })
    })
  }

  renderLanguageColor = () => {
    const {item} = this.props;
    switch (item.language?.toLowerCase()) {
      case "typescript":
        return "#3178C6";
      case "javascript":
        return "#F7DF1E";
      case "java":
        return "#4E7896";
      default:
        return Colors.gray;
    }
  }

  render() {
    const {item} = this.props;
    const {isLoading, commits} = this.state;
    return (
      <a href={item.html_url} target={"_blank"} className={"git-repository-card shadow hover-002"}>
        <div className={"title"}>{item.name || "-"}</div>
        <div className={"description"}>{item.description || "-"}</div>

        <div className={"updatedAt"} style={{color: Colors.gray}}>마지막 업데이트 {DateUtils.prettyDate(dayjs(item.pushed_at))}</div>
        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
          <div className={"language"} style={{fontWeight: "bold", color: this.renderLanguageColor()}}>{item.language || "-"}</div>
          <div className={"commits"} style={{color: Colors.gray, position: "relative"}}>
            {isLoading && <Loading size={25} />}
            {(!isLoading && commits) && "commits " + commits}
          </div>
        </div>
      </a>
    );
  }
}
