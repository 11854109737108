import React from "react";
import {IProjectModalContext, ProjectModalContext} from "../../../context/ProjectModalContext";
import {PROJECT} from "../../../constants/career/ZEROWEB";
import SubwayNavigationDescription from "./zeroweb/subway-navigation/SubwayNavigationDescription";
import BellDescription from "./zeroweb/bell/BellDescription";
import AnuroTTAnGSDescription from "./zeroweb/anuro-tta-gs/AnuroTTAnGSDescription";
import BiodomeDescription from "./zeroweb/biodome/BiodomeDescription";
import HelperItDescription from "./zeroweb/helperit/HelperItDescription";
import WebmasterDescription from "./zeroweb/webmaster/WebmasterDescription";

interface Props {}
interface State {}

export default class ProjectModalDescriptionTab extends React.Component<Props, State> {

  static contextType = ProjectModalContext;
  context!: IProjectModalContext;

  render() {
    return (
      <>
        {/* 2024 */}
        {this.context.name === PROJECT.NAME.BELL && <BellDescription />}
        {this.context.name === PROJECT.NAME.SUbWAY_NAVIGATION && <SubwayNavigationDescription />}

        {/* 2023 */}
        {this.context.name === PROJECT.NAME.ANURO_TTA_GS && <AnuroTTAnGSDescription />}

        {/* 2022 */}
        {this.context.name === PROJECT.NAME.BIODOME && <BiodomeDescription />}

        {/* 2021 */}
        {this.context.name === PROJECT.NAME.HELPER_IT && <HelperItDescription />}
        {this.context.name === PROJECT.NAME.WEBMASTER && <WebmasterDescription />}
      </>
    );
  }
}
