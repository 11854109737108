import React from "react";
import "./loading.css";

interface Props {
  size?: number;
  color?: string;
}
interface State {}

export default class Loading extends React.Component<Props, State> {
  render() {
    const {
      size = 50,
      color,
    } = this.props;
    return (
      <svg
        className={"loading-container"}
        width={size}
        height={size}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="circlePath"
          d="
            M 25, 50
            a 25,25 0 1,1 50,0
            25,25 0 1,1 -50,0
          "
        />
        <text id="text" font-size="20" nt-weight="bold" fill={color}>
          <textPath href="#circlePath">
            로딩중입니다...⭐️
          </textPath>
        </text>
      </svg>
    );
  }
}
