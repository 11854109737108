export const ActivityPhotos: string[] = [
  require("./activity-photos/tta/20220913 2113.jpg"),
  require("./activity-photos/tta/20220930 0535 새벽출장길.jpg"),
  require("./activity-photos/tta/20220930 1119.jpg"),
  require("./activity-photos/tta/20220930 1719.jpg"),
  require("./activity-photos/tta/20220930 1720.jpg"),
  require("./activity-photos/tta/20221202_153036.jpg"),
  require("./activity-photos/tta/20230113 - 1.jpg"),
  require("./activity-photos/tta/20230113_200454.jpg"),
  require("./activity-photos/tta/20230119_114946.jpg"),
  require("./activity-photos/tta/20230125 - 1.jpg"),
  require("./activity-photos/tta/20230125 - 2.jpg"),
  require("./activity-photos/tta/20230125 - 3.jpg"),
  require("./activity-photos/tta/20230125 - 4.jpg"),
  require("./activity-photos/tta/20230125 - 6.jpg"),
  require("./activity-photos/tta/20230125 - 7.jpg"),
  require("./activity-photos/tta/20230125 - 8.jpg"),
  require("./activity-photos/tta/20230125_200451.jpg"),
  require("./activity-photos/tta/20230126 - 1.jpg"),
  require("./activity-photos/tta/20230126 - 2.jpg"),
  require("./activity-photos/tta/20230126_130519.jpg"),
  require("./activity-photos/tta/20230126_210935.jpg"),
  require("./activity-photos/tta/20230127_032553.jpg"),
  require("./activity-photos/tta/20230206 - 1.jpg"),
  require("./activity-photos/tta/20230206 - 2.jpg"),
  require("./activity-photos/tta/20230206 2102.jpg"),
  require("./activity-photos/tta/20230206_195910.jpg"),
  require("./activity-photos/tta/20230206_210300.jpg"),
  require("./activity-photos/tta/20230207 - 1.jpg"),
  require("./activity-photos/tta/20230208 - 1.jpg"),
  require("./activity-photos/tta/20230208 2250.jpg"),
  require("./activity-photos/tta/20230208_211545.jpg"),
  require("./activity-photos/tta/20230209 1108.jpg"),
  require("./activity-photos/tta/20230210 - 1.jpg"),
  require("./activity-photos/tta/20230210 - 2.jpg"),
  require("./activity-photos/tta/20230210 - 3.jpg"),
  require("./activity-photos/tta/20230210 - 4.jpg"),
  require("./activity-photos/tta/20230210_102823.jpg"),
  require("./activity-photos/tta/20230210_123916.jpg"),
  require("./activity-photos/tta/20230210_140501.jpg"),
  require("./activity-photos/tta/20230210_140507.jpg"),
  require("./activity-photos/tta/20230210_140644.jpg"),
  require("./activity-photos/tta/20230220_162351.jpg"),
];
