export const ActivityPhotos: string[] = [
  require("./activity-photos/20221125_154208.jpg"),
  require("./activity-photos/20221128_105201.jpg"),
  require("./activity-photos/20221201_162533.jpg"),
  require("./activity-photos/20221205_130920.jpg"),
  require("./activity-photos/20221205_164013.jpg"),
  require("./activity-photos/20221206_100359.jpg"),
  require("./activity-photos/20221206_101224.jpg"),
  require("./activity-photos/20221206_171415.jpg"),
  require("./activity-photos/20221206_183508.jpg"),
  require("./activity-photos/20221206_222343.jpg"),
  require("./activity-photos/20221207_110552.jpg"),
  require("./activity-photos/20221207_110720.jpg"),
  require("./activity-photos/20221207_112014.jpg"),
  require("./activity-photos/20221207_125111.jpg"),
  require("./activity-photos/20221212_114604.jpg"),
  require("./activity-photos/20221212_114956.jpg"),
  require("./activity-photos/20221213_101941.jpg"),
  require("./activity-photos/20221213_103330.jpg"),
  require("./activity-photos/20230417_120734.jpg"),
  require("./activity-photos/20230417_175121.jpg"),
  require("./activity-photos/20230417_193826.jpg"),
  require("./activity-photos/20230417_195537.jpg"),
  require("./activity-photos/20230418_155912.jpg"),
  require("./activity-photos/20230418_194837.jpg"),
  require("./activity-photos/20230418_202510.jpg"),
  require("./activity-photos/20230418_204816.jpg"),
  require("./activity-photos/20230419_094407.jpg"),
  require("./activity-photos/20230419_100116.jpg"),
  require("./activity-photos/20240220_114409.jpg"),
  require("./activity-photos/20240220_121303.jpg"),
  require("./activity-photos/20240220_152210.jpg"),
  require("./activity-photos/20240220_183700.jpg"),
  require("./activity-photos/20240220_190904.jpg"),
  require("./activity-photos/20240221_174232.jpg"),
  require("./activity-photos/20240227_145837.jpg"),
  require("./activity-photos/20240229_102402.jpg"),
  require("./activity-photos/20240307_130128.jpg"),
  require("./activity-photos/20240307_144211.jpg"),
  require("./activity-photos/20240307_145148.jpg"),
  require("./activity-photos/20240308_122041.jpg"),
  require("./activity-photos/20240308_143325.jpg"),
  require("./activity-photos/20240308_143439.jpg"),
];
