import React from "react";
import {Colors} from "../../../constants/Colors";

interface Props {}
interface State {}

export default class MyProfile extends React.Component<Props, State> {
  render() {
    return (
      <div className={"profile-item my-profile"}>
        <div style={{padding: "20px 0"}}>
          <span className={"profile-item-title text"}>내정보</span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>이름</span>
          <span className={"text"}>오지연</span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>생년월일</span>
          <span className={"text"}>1994.02.15</span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>이메일</span>
          <span className={"text"}>ojy2333@gmail.com</span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>학력</span>
          <span className={"text"}>동서대학교(컴퓨터공학전공)</span>
        </div>

        <div className={"item"}>
          <span className={"title text"}>깃허브</span>
          <span className={"text"}>
            <a target={"_blank"} href={"https://github.com/ozee94"} style={{color: Colors.blue}}>
              https://github.com/ozee94
            </a>
          </span>
        </div>
        {
          process.env.NODE_ENV === "development" && <div className={"item"}>
            <span className={"title text"}>경력인증(KOSA)</span>
            <span className={"text"}>
              https://github.com/ozee94
            </span>
          </div>
        }
      </div>
    );
  }
}
