import css from "../listView.module.css";
import React from "react";
import Collapsible from "react-collapsible";

interface Props {}
interface State {}

const columnFlex = [0.3, 0.6, 0.6];

export default class CareerItem extends React.PureComponent<Props, State> {

	renderItemTitle = () => {
		return (
			<div className={css.itemTitle}>
				🏢{' '}경력
			</div>
		)
	}

	renderItemContent = () => {
		const renderTrigger = () => {
			return (
				<thead>
				<tr>
					<td style={{flex: columnFlex[0]}}>회사명</td>
					<td style={{flex: columnFlex[1]}}>담당업무</td>
					<td style={{flex: columnFlex[2]}}>재직기간</td>
				</tr>
				</thead>
			)
		}

		return (
			<table>
				<Collapsible trigger={renderTrigger()} open={true} transitionTime={200} triggerStyle={{width: "100%", display: "flex", flex: 1, borderRadius: "4px 4px 0 0"}}>
					<tbody>
					<tr>
						<td style={{flex: columnFlex[0]}}>제로웹</td>
						<td style={{flex: columnFlex[1]}}>웹/앱 프론트엔드 개발자</td>
						<td style={{flex: columnFlex[2]}}>2021.06 ~ 2024.08 (3년 3개월)</td>
					</tr>
					<tr>
						<td style={{flex: columnFlex[0]}}>거영해운</td>
						<td style={{flex: columnFlex[1]}}>해도 발주 및 선박 예산 편성 및 집행</td>
						<td style={{flex: columnFlex[2]}}>2018.06 ~ 2020.01 (1년 9개월)</td>
					</tr>
					</tbody>
				</Collapsible>
			</table>
		)
	}

	render() {
		return (
			<div>
				{this.renderItemTitle()}
				<div style={{height: 10}}/>
				{this.renderItemContent()}
			</div>
		);
	}
}
