import React from "react";
import CommitRoundedIcon from '@mui/icons-material/CommitRounded';

interface Props {
  createdAt: string;
  isFirstRow: boolean;
}
interface State {}

export default class CommitHeader extends React.Component<Props, State> {
  render() {
    const {createdAt, isFirstRow} = this.props;
    return (
      <div className={"aaaa commit-header"}>
        {!isFirstRow && <div style={{width: 2, height: 20, marginLeft: 27, backgroundColor: "rgba(31,35,40,0.15)"}}/>}
        <div style={{color: "#656d76", fontSize: 14, padding: "0 20px", display: "flex", alignItems: "center"}}>
          <CommitRoundedIcon style={{width: 16, height: 16, marginRight: 10}}/> Commits on {createdAt}
        </div>
        <div style={{width: 2, height: 20, marginLeft: 27, backgroundColor: "rgba(31,35,40,0.15)"}}/>
      </div>
    );
  }
}
