import qs from "qs";
import axios, {AxiosHeaders, HeadersDefaults, RawAxiosRequestHeaders} from 'axios';

export const BASE_URL: string = "";

// Timeout
const TIME_OUT_SECONDS = 30;
const TIME_OUT_MILLISECONDS = TIME_OUT_SECONDS * 1000;

// Default headers
const DEFAULT_HEADERS: RawAxiosRequestHeaders | AxiosHeaders | Partial<HeadersDefaults> = {
  Authorization: "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",
};

export const api = axios.create({
  timeout: TIME_OUT_MILLISECONDS,
  baseURL: BASE_URL,
  headers: DEFAULT_HEADERS
});

// Params serializer
api.defaults.paramsSerializer = params => {
  return qs.stringify(params);
};

// Add a request interceptor
api.interceptors.request.use(async (config) => {

  return config;
});

// Add a response interceptor
api.interceptors.response.use(
  async (response) => {

    return response;
  },
  async (error) => {
    // Request failed, e.g. HTTP code 500
    let preventLogging = false;

    switch (error?.response?.status) {
      case 401: {
        break;
      }
      case 404: {
        break;
      }
    }

    if (!preventLogging) {
      console.warn('api.onRejected', error);
    }

    return Promise.reject(error);
  },
);
