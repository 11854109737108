import React from "react";
import {CAREER} from "../../../constants/career/CAREER";
import {Colors} from "../../../constants/Colors";

interface Props {}
interface State {}

export default class CareerInformation extends React.Component<Props, State> {
  render() {
    return (
      <div style={{
        margin: "0px 90px 10px",
        textAlign: "right",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end"
        }}>
          {
            Object.keys(CAREER.TYPE).map((i: any, index) => {
              return (
                <div key={index} style={{display: "inline-flex", flexDirection: "row", alignItems: "center", marginRight: (Object.keys(CAREER.TYPE).length -1 ) === index ? 0 : 15}}>
                  <div style={{width: 10, height: 10, borderRadius: 100, backgroundColor: CAREER.getColor(i)}}/>
                  <div style={{width: 10}}/>
                  {CAREER.toString(i as any)}
                </div>
              )
            })
          }
        </div>
        <div style={{height: 5}}/>
        <div style={{textAlign: "right", color: Colors.gray}}>굵은 글자를 눌러보세요</div>
      </div>
    );
  }
}
