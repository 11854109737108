import React, {ReactElement} from "react";
import BaseModal from "../../common/container/base-modal/BaseModal";
import "./projectModalContainer.css";

interface Props {
  open: boolean;
  handleClose?: () => void;
  children: ReactElement | ReactElement[];
}
interface State {}

export const ProjectModalHeader = ({children}) =>  <div className={"project-modal-header shadow"}>{children}</div>
export const ProjectModalTitle = ({children}) =>  <div className={"project-modal-title"}>{children}</div>
export const ProjectModalInformation = ({children}) =>  <div className={"project-modal-information"}>{children}</div>
export const ProjectModalBadge = ({children}) =>  <div className={"project-modal-badge"}>{children}</div>
export const ProjectModalTab = ({children}) =>  <div className={"project-modal-tab"}>{children}</div>
export const ProjectModalContent = ({children}) =>  <div className={"project-modal-content"}>{children}</div>

export default class ProjectModalContainer extends React.Component<Props, State> {
  static Header = ProjectModalHeader;
  static Title = ProjectModalTitle;
  static Information = ProjectModalInformation;
  static Badge = ProjectModalBadge;
  static Tab = ProjectModalTab;
  static Content = ProjectModalContent;

  render() {
    const {open, handleClose, children} = this.props;

    return (
      <BaseModal
        className={"project-modal"}
        width={"80%"}
        height={"90vh"}
        open={open} handleClose={handleClose} closeBackDropClick>
        <div style={{height: "100%", overflow: "auto"}}>
          {children}
        </div>
      </BaseModal>
    );
  }
}
