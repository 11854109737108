import {IAppScreenShotImage} from "../../../../../models/IAppScreenShotImage";

export const AppScreenShotImages: IAppScreenShotImage[] = [
  {mine: false, image: require("./app-screenshots/1 회사 소개.png")},
  {mine: false, image: require("./app-screenshots/2 청소 문의.png")},
  {mine: true, image: require("./app-screenshots/3 활용팁.png")},
  {mine: true, image: require("./app-screenshots/4 이벤트.png")},
  {mine: true, image: require("./app-screenshots/5 헬퍼가입.png")},
  {mine: true, image: require("./app-screenshots/6 후기.png")},
  {mine: true, image: require("./app-screenshots/7 관리자 - 메인.png")},
  {mine: true, image: require("./app-screenshots/8 관리자 - 심부름 관리.png")},
];
