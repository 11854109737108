import React from "react";
import {IGitCommit} from "../../../models/IGitCommit";
import {DateUtils} from "../../../utils/DateUtils";
import "./commitItem.css";

interface Props {
  item: IGitCommit;
  isEndOfDate: boolean;
}
interface State {}

export default class CommitItem extends React.Component<Props, State> {

  render() {
    const {item, isEndOfDate} = this.props;
    return (
      <div className={`commit-item-container ${isEndOfDate && "endOfDate"}`}>
        <div>
          <div style={{fontWeight: "bold"}}>{item.commit.message}</div>
          <div style={{height: 5}}/>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", fontSize: 14, color: "#656d76"}}>
            <img alt="user_avatar" width={16} height={16} style={{borderRadius: 100, marginRight: 10}} src={item.author.avatar_url}/>
            <span>{item.author.login} committed {DateUtils.prettyDate(item.commit.author.date)}</span>
          </div>
        </div>
        <div style={{fontSize: 12, color: "#656d76"}}>
          {item.sha.substring(0, 7)}
        </div>
      </div>
    );
  }
}
