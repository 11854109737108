import React from 'react';
import Router from './routes';
import {BrowserRouter} from "react-router-dom";

export default class App extends React.Component<any, any> {
  render() {
    return (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    )
  }
}
