import React, {createRef, ReactElement, ReactNode} from "react";

interface Props {
  children: ReactNode;
}
interface State {
  isOverflowed: boolean;
}

export default class FlowText extends React.Component<Props, State> {

  refDiv = createRef<HTMLDivElement>();

  state: State = {
    isOverflowed: false,
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  updateState() {
    if (this.refDiv.current === null) {
      return;
    }

    const isOverflowed =
      this.refDiv.current.offsetWidth < this.refDiv.current.scrollWidth ||
      this.refDiv.current.offsetHeight < this.refDiv.current.scrollHeight;

    if (isOverflowed === this.state.isOverflowed) {
      return;
    }

    this.setState({ isOverflowed: isOverflowed });
  }
  render() {
    const {children} = this.props;
    const {isOverflowed} = this.state;
    return (
      <div ref={this.refDiv} className={`${isOverflowed && "marquee"}`}>
        {children}
      </div>
    );
  }
}
