import React, {ReactElement} from "react";
import {Modal, ModalProps} from "@mui/material";
import "./baseModal.css";

interface Props extends ModalProps {
  children: ReactElement;
  className?: string;
  width?: number | string;
  height?: number | string;
  handleClose?: () => void
  closeBackDropClick?: boolean;
}
interface State {}

export default class BaseModal extends React.Component<Props, State> {

  onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    const {closeBackDropClick = false} = this.props;
    if (reason === 'backdropClick' && !closeBackDropClick ) {return null;}
    this.props.handleClose && this.props.handleClose();
  }

  render() {
    const {
      open,
      children,
      className,
      width,
      height
    } = this.props;
    return (
      <Modal open={open} onClose={this.onClose} >
        <div className={`modal-container ${className}`} style={{width, height}}>
          {children}
        </div>
      </Modal>
    );
  }
}
