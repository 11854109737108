import React from "react";
import {AppScreenShotImages} from "./AppScreenShotImages";
import Image from "../../../../common/image/Image";

interface Props {}
interface State {}

export default class AnuroTTAnGSDescription extends React.Component<Props, State> {

  render() {
    return (
      <div className={"project-modal-content-description"}>
        <div className={"item"}>
          <div className={"title"}>프로젝트 설명</div>
          <div className={"description"}>
            등록된 CCTV를 통해 특정 지역 내에서 발생하는 긴급 상황을 모니터링하고,<br/>
            연계된 5대 기관으로부터 발생한 상황 정보를 실시간으로 수신하여 확인하고 전달하는 시스템<br/>
            (* 5대 연계 - 119 긴급출동 지원서비스, 긴급재난상황 지원서비스, 112 종합상황실 긴급영상 지원서비스, 112 긴급출동 지원서비스, 사회적 약자 지원서비스)
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>기간</div>
          <div className={"description"}>
            2023-05-30 ~ 2023-10-17 (GS 1급 인증)<br/>
            2022-06-01 ~ 2023-02-28 (TTA 인증)
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>역할/기여도</div>
          <div className={"description"}>
            웹 대시보드 <span style={{fontWeight: "bold"}}>50%</span><br/>
            소켓통신 프로그램 <span style={{fontWeight: "bold"}}>100%</span><br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>주요업무</div>
          <div className={"description"}>
            1. GIS, 통계, 재난 화면 구현 및 RESTful API 데이터 연동<br />
            2. TTA 필수 기능인 5대 연계 Socket통신 GUI프로그램 제작<br />
            3. 5대 연계와 소켓 통신 시 데이터 분석을 위한 데이터 Parser 구현<br />
            4. 웹 소켓으로 RTSP CCTV 스트리밍
          </div>
        </div>

        <div className={"title"} style={{paddingTop: 12}}>사진</div>
        <div className={"image-list"}>
          {AppScreenShotImages.map((i) => {
            return (
              <Image key={i.image} src={i.image} className={`${i.mine && "mine"}`} showFullScreen/>
            )
          })}
        </div>
      </div>
    );
  }
}
