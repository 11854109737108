import React from "react";
import ScreenContainer from "../../components/common/container/base-screen/BaseScreen";
import MySkill from "../../components/screens/profile/MySkill";
import MyProfile from "../../components/screens/profile/MyProfile";
import Introduce from "../../components/screens/profile/Introduce";
import {ReactComponent as FEMALE_FACE} from "../../components/common/sidebar/icons/user-face-female.svg";
import "./profile.css";
import PaperFlake from "../../components/common/flake/PaperFlake";

interface Props {}
interface State {}

export default class Profile extends React.Component<Props, State> {

  renderHeader = () => {
    return (
      <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>프로필
        <div style={{width: 20}}/>
        <FEMALE_FACE width={40} height={40}/>
      </div>
    )
  }

  render() {
    return (
      <ScreenContainer>
        {/*<Header title={this.renderHeader()} />*/}
        <PaperFlake />
        <div className={"profile-container"}>
          <Introduce />
          <div className={"divide"}/>
          <MyProfile />
          <div className={"divide"}/>
          <MySkill />
        </div>
      </ScreenContainer>
    );
  }
}
