import React from "react";
import {
  CSSObject,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled, Theme
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import "./index.css";
import {NavLink} from "react-router-dom";
import {SIDEBAR_LIST} from "./SidebarList";
import Logo from "../logo/Logo";
import Lottie from "react-lottie";
import confetti from "../../../assets/animation/confetti.json";

interface Props {
  autoOpen: boolean;
}
interface State {
  open: boolean;
}

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => {
  return {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `75px`,
    [theme.breakpoints.up('sm')]: {
      width: `83px`,
    },
  }
};

export default class Sidebar extends React.Component<Props, State> {

  state: State = {
    open: this.props.autoOpen,
  }

  interval: NodeJS.Timer | null = null;
  componentDidMount() {
    if (this.state.open) {
      this.clearTimeout();
      this.interval = setTimeout(this.onMouseLeave, 1000);
    }
  }

  onMouseEnter = () => {
    this.clearTimeout();
    this.setState({open: true});
  }
  onMouseLeave = () => this.setState({open: false});

  clearTimeout = () => {
    if (this.interval) {
      clearTimeout(this.interval);
      this.interval = null;
    }
  }

  render() {
    const {open} = this.state;
    return (
      <Drawer
        className={"sidebar"}
        variant="permanent" open={open}
        onMouseOver={this.onMouseEnter}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div style={{position: "relative", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: open? 20 : 10, borderBottom: "1px solid #CCC"}}>
          {
            open &&
              <div className={"confetti"}>
                <Lottie
                  isClickToPauseDisabled
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: confetti,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    }
                  }}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
          }
          <Logo size={open ? 80 : 40}/>
          {open && <div style={{marginTop: 10}}>안녕하세요 🖐️</div>}
          {open && <div style={{}}>오지연입니다️ 🥳</div>}
        </div>

        <List style={{width: "100%", height: "100vh"}}>
          {SIDEBAR_LIST.map((item) => {
            if (process.env.NODE_ENV !== "development" && item.to === "/commit") {return null;}
            return (
              <ListItem key={item.title} disablePadding className={"hover-005"} sx={{ display: 'block', padding: `5px 10px` }}>
                <ListItemButton
                  component={NavLink}
                  to={item.to}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }}/>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    );
  }
}
