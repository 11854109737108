import {Colors} from "../Colors";
import {IMAGE_UUID} from "../IMAGE_UUID";

export interface ICareer {
  title: string;
  startDate: string;
  endDate?: string;
  url?: string;
  img?: keyof typeof IMAGE_UUID;
  type?: CAREER.TYPE;
  color?: string;
}

export namespace CAREER {
  export enum TYPE {
    CERTIFICATE = "CERTIFICATE",
    WORK = "WORK",
  }

  export function toString(type?: TYPE): string {
    switch (type) {
      case CAREER.TYPE.CERTIFICATE:
        return "자격증 & 증명서";
      case CAREER.TYPE.WORK:
        return "경력";
      default:
        return "";
    }
  }

  export function getColor(type?: TYPE): string {
    switch (type) {
      case CAREER.TYPE.CERTIFICATE:
        return Colors.blue;
      case CAREER.TYPE.WORK:
        return Colors.pink;
      default:
        // return Colors.gray;
        return "";
    }
  }
}

export const CAREERS = {
  "2017": [
    {
      title: "SQLD 취득",
      startDate: "2017-01-03",
      type: CAREER.TYPE.CERTIFICATE,
      img: IMAGE_UUID.certificate_sqld,
    },
  ],
  "2018": [
    {
      title: "동서대학교 졸업(컴퓨터공학전공)",
      startDate: "2018-02-09",
      img: IMAGE_UUID.certificate_grade,
    },
    {
      title: "거영해운/사무직 (1년 7개월)",
      startDate: "2018-05-02",
      endDate: "2020-01-19",
      type: CAREER.TYPE.WORK,
    //   해도 발주, 예산 작성 및 선박 관리하는 감독 사무적인 보조 역할
    },
  ],
  "2019": [],
  "2020": [
    {
      title: "토익(700)",
      startDate: "2020-01-05",
      type: CAREER.TYPE.CERTIFICATE,
      img: IMAGE_UUID.certificate_toeic,
    },
    {
      title: "스프링기반 Java프레임워크 개발자 교육",
      startDate: "2020-04-16",
      endDate: "2020-11-09",
      type: CAREER.TYPE.CERTIFICATE,
      img: IMAGE_UUID.certificate_spring,
    },
    {
      title: "정보처리기사(필기)",
      startDate: "2020-06-05",
      type: CAREER.TYPE.CERTIFICATE,
    },
  ],
  "2021": [
    {
      title: "정보처리기사(실기)",
      startDate: "2021-06-02",
      type: CAREER.TYPE.CERTIFICATE,
      img: IMAGE_UUID.certificate_engineer,
    },
    {
      title: "제로웹/개발",
      startDate: "2021-06-14",
      endDate: "",
      type: CAREER.TYPE.WORK,
      url: "/career/zeroweb",
    },
  ],
  "2022": [
    // TODO 아래 케이스 넣으면 css 이그러짐 확인해보기
    // {
    //   title: "정보처리기사(실기)",
    //   startDate: "2021-04-02",
    //   type: CAREER.TYPE.CERTIFICATE,
    //   img: IMAGE_UUID.certificate_engineer,
    // },
    // {
    //   title: "정보처리기사(실기)",
    //   startDate: "2021-07-02",
    //   type: CAREER.TYPE.CERTIFICATE,
    //   img: IMAGE_UUID.certificate_engineer,
    // },
  ],
  "2023": [
    {
      title: "네트워크관리사 2급 필기",
      startDate: "2023-02-28",
      type: CAREER.TYPE.CERTIFICATE,
    },
  ],
  "2024": [
    {
      title: "네트워크관리사 2급 실기",
      startDate: "2024-07-02",
      type: CAREER.TYPE.CERTIFICATE,
      img: IMAGE_UUID.certificate_network,
    },
  ],
};
