import css from "./listView.module.css";
import React from "react";
import CareerItem from "./items/CareerItem";

interface Props {}
interface State {}

export default class ListView extends React.PureComponent<Props, State> {

	render() {
		return (
			<div className={css.listViewContainer}>
				<CareerItem />
			</div>
		);
	}
}
