import React, {ImgHTMLAttributes} from "react";
import Loading from "../loading/Loading";
import {Modal, Tooltip} from "@mui/material";

interface Props extends ImgHTMLAttributes<HTMLImageElement | HTMLVideoElement> {
  showFullScreen?: boolean;
}
interface State {
  onLoad: boolean;
  onLoadFullScreen: boolean;
  open: boolean;
}

export default class Image extends React.Component<Props, State> {

  state: State = {
    onLoad: false,
    onLoadFullScreen: false,
    open: false,
  }

  handleOpen = () => this.setState({open: true} as State);
  handleClose = () => this.setState({open: false} as State);

  render() {
    const {src, showFullScreen, alt, className, onClick} = this.props;
    const {onLoad, onLoadFullScreen, open} = this.state;

    const altArr = src!.split("/")?.[3] || "";
    const _alt = altArr.split(".")[0]
    return (
      <>
        <Tooltip key={src} title={`${_alt}${showFullScreen ? " - 눌러서 크게 보기" : ""}`} placement={"bottom"}>
          <div style={{position: "relative"}}>
            {!onLoad && <Loading size={25}/>}
            {
              src?.includes("mp4") ?
                <video
                  controls
                  autoPlay
                  muted
                  {...this.props}
                  className={`${className} ${showFullScreen && "pointerQ"} hover-005`}
                  onLoad={() => this.setState({onLoad: true} as State)}
                  onClick={(e) => onClick ? onClick(e) : showFullScreen && this.handleOpen()}
                >
                  <source src={src} type={"video/mp4"}/>
                </video> :
                <img {...this.props}
                     alt={alt || _alt}
                     className={`${className} ${showFullScreen && "pointerQ"} hover-005`}
                     onLoad={() => this.setState({onLoad: true} as State)}
                     onClick={(e) => onClick ? onClick(e) : showFullScreen && this.handleOpen()}/>
            }
          </div>
        </Tooltip>

        {
          open &&
          <Modal open={open} onClose={this.handleClose} style={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <span style={{position: "relative"}}>
              {!onLoadFullScreen && <Loading size={25}/>}
              {
                src?.includes("mp4") ?
                  <video
                    controls
                    autoPlay
                    muted
                    style={{borderRadius: 10, position: "relative", maxHeight: "80vh", maxWidth: "100%"}}
                    onLoad={() => this.setState({onLoadFullScreen: true} as State)}
                  >
                    <source src={src} type={"video/mp4"}/>
                  </video> :
                  <img
                    style={{borderRadius: 10, position: "relative", maxHeight: "80vh", maxWidth: "100%"}}
                    src={src}
                    alt={_alt}
                    onLoad={() => this.setState({onLoadFullScreen: true} as State)}
                  />
              }
            </span>
          </Modal>
        }
      </>
    );
  }
}
