import React from "react";
import {AppScreenShotImages} from "./AppScreenShotImages";
import Image from "../../../../common/image/Image";

interface Props {}
interface State {}

export default class WebmasterDescription extends React.Component<Props, State> {

  render() {
    return (
      <div className={"project-modal-content-description"}>
        <div className={"item"}>
          <div className={"title"}>프로젝트 설명</div>
          <div className={"description"}>
            제로웹에서 호스팅중인 모바일 홈페이지 유지보수
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>기간</div>
          <div className={"description"}>2021.06 ~<br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>역할/기여도</div>
          <div className={"description"}>
            2024년 13건 중 12건 (92%)<br/>
            2023년 71건 중 63건 (88%)<br/>
            2022년 170건 중 97건 (57%)<br/>
            2021년 180건 중 14건 (7%)<br/>
          </div>
        </div>

        <div className={"item"}>
          <div className={"title"}>주요업무</div>
          <div className={"description"}>
            1. 홈페이지 기능 오류 수정<br />
            2. SSL 인증서 갱신<br />
            3. DNS 레코드 수정<br />
            4. 홈페이지 코드 및 데이터 백업<br />
          </div>
        </div>

        <div className={"title"} style={{paddingTop: 12}}>사진</div>
        <div className={"image-list"}>
          {AppScreenShotImages.map((i) => {
            return (
              <Image key={i.image} src={i.image} className={`${i.mine && "mine"}`} showFullScreen/>
            )
          })}
        </div>
      </div>
    );
  }
}
