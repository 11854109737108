import React from "react";
import ProjectModalContainer from "../../ProjectModalContainer";
import LanguageBadge from "../../../../common/LanguageBadge";
import PlumpLineIcon from "../../../../common/icon/PlumpLineIcon";
import ProjectModalDescriptionTab from "../../ProjectModalDescriptionTab";
import ProjectModalPhotoTab from "../../ProjectModalPhotoTab";
import {Colors} from "../../../../../constants/Colors";
import {PROJECT} from "../../../../../constants/career/ZEROWEB";
import {IProjectModalContext, ProjectModalContext} from "../../../../../context/ProjectModalContext";

interface Props {}
interface State {
  activeTab: "description" | "photo-tta" | "photo-gs" | "blog";
}

export default class ProjectAnuroTTAnGS extends React.Component<Props, State> {

  static contextType = ProjectModalContext;
  context!: IProjectModalContext;

  state: State = {
    activeTab: "description",
  }

  onChangeTab = (tab: "description" | "photo-tta" | "photo-gs"  | "blog") => this.setState({activeTab: tab} as State);

  render() {
    const {activeTab} = this.state;
    return (
      <div>
        <ProjectModalContainer.Header>
          <ProjectModalContainer.Title>{PROJECT.toString(this.context.name)}</ProjectModalContainer.Title>

          <ProjectModalContainer.Information>
            {/* 기술 스택 */}
            <ProjectModalContainer.Badge>
              <LanguageBadge logo={"React"} name={"React"} backgroundColor={"61DAFB"} />
              <LanguageBadge logo={"JavaScript"} backgroundColor={"F7DF1E"} />
              <LanguageBadge name={"RESTFul API"} backgroundColor={"333333"} />
              <LanguageBadge logo={"MobX"} backgroundColor={"FF9955"} />
              <LanguageBadge logo={"Naver"} name={"Naver Maps"} backgroundColor={"03C75A"} />

              <LanguageBadge logo={"Java"} backgroundColor={"3A75B0"} />
              <LanguageBadge logo={"JAVA SWing"} backgroundColor={"3A75B0"} />
              <LanguageBadge logo={"apachenetbeanside"} name="NetBeans" backgroundColor={"1B6AC6"} />
              <LanguageBadge logo={"JavaFx"} backgroundColor={"3A75B0"} />
              <LanguageBadge name={"Socket"} backgroundColor={"333333"} />
            </ProjectModalContainer.Badge>

            <ProjectModalContainer.Tab>
              <PlumpLineIcon name={"gridRules"} title={"소개"}
                             style={{stroke: activeTab === "description" ? Colors.black : Colors.gray}}
                             onClick={() => this.onChangeTab("description")}/>
              <PlumpLineIcon name={"photo"} title={"사진첩(TTA)"}
                             style={{stroke: activeTab === "photo-tta" ? Colors.black : Colors.gray}}
                             onClick={() => this.onChangeTab("photo-tta")}/>
              <PlumpLineIcon name={"photo"} title={"사진첩(GS)"}
                             style={{stroke: activeTab === "photo-gs" ? Colors.black : Colors.gray}}
                             onClick={() => this.onChangeTab("photo-gs")}/>
              {/*<PlumpLineIcon name={"notebook"} title={"일기"}*/}
              {/*               style={{stroke: activeTab === "blog" ? Colors.black : Colors.gray}}*/}
              {/*               onClick={() => this.onChangeTab("blog")}/>*/}
            </ProjectModalContainer.Tab>
          </ProjectModalContainer.Information>
        </ProjectModalContainer.Header>

        <ProjectModalContainer.Content>
          {activeTab === "description" && <ProjectModalDescriptionTab />}
          {activeTab === "photo-tta" && <ProjectModalPhotoTab photoName={"photo-tta"}/>}
          {activeTab === "photo-gs" && <ProjectModalPhotoTab photoName={"photo-gs"}/>}
        </ProjectModalContainer.Content>
      </div>
    );
  }
}
